



























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseTable extends Vue {
  @Prop() classes!: string | string[];
  @Prop(Boolean) isBorderless!: boolean;
  @Prop() tableClasses!: string[];

  /**
   * Function to extract the class names passed from parent/consumer.
   */
  getClassNames(): string {
    if (this.classes && this.classes.length) {
      if (Array.isArray(this.classes)) {
        return this.classes.join(' ');
      }
      return this.classes;
    }
    return '';
  }
}
