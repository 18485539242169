






















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class BasePagination extends Vue {
  @Prop() public page!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Number) public perPage!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop({ type: Array, default: () => [50, 100, 150] })
  public perPageOptions!: number[];
  public insertedPageNumber: number = Number(this.page);

  updatePage(page: number): void {
    // Ensure page range is always bounded
    const clampedPage = Math.min(
      Math.max(1, Number(page)),
      this.totalNumberOfPage
    );
    this.insertedPageNumber = clampedPage;
    this.$emit('update:page', clampedPage);
  }

  // Only allow numeral inputs
  checkDigit = (event: KeyboardEvent) => {
    if (event.key.length === 1 && isNaN(Number(event.key))) {
      event.preventDefault();
    }
  };
}
